import React from 'react'
import {useTranslation, Trans} from 'react-i18next'
import Dialog from './Dialog'
import {EmbeddedLink} from '../components/EmbeddedLink'
import {AMPLITUDE_SCREEN_NAME} from '../helperComponents/Analytics/AnalyticsConstants'
import {utils} from '../helperComponents/Utils'
import {replaceBrand} from '../helperComponents/contentUtils'

export default function TurnOnOffDialog(props) {
  const {t} = useTranslation()

  function determineTurnOnOrTurnOffText() {
    return props.isTurnOn ? t('lbl_turn_off') : t('lbl_turn_on')
  }

  function renderVehicleInfo() {
    if (props.vehicleName) {
      return (
        <>
          <span className="turn-on-off-dialog__section-title">{t('lbl_your_vehicle')}</span>
          <p className="turn-on-off-dialog__vehicle-name">{props.vehicleName}</p>
        </>
      )
    }
  }

  function getScreenName() {
    return props.isTurnOn ? AMPLITUDE_SCREEN_NAME.TURN_OFF : AMPLITUDE_SCREEN_NAME.TURN_ON
  }

  function renderContent() {
    const paragraphs = utils.getSplitParagraphs(props)

    return (
      <>
        {paragraphs.map((paragraph, index) => (
          <p key={`turn-on-off-p-${index}`} className="turn-on-off-dialog__paragraph">
            <Trans
              components={
                props.contentEmbedLink && {
                  [props.contentEmbedLink.varKey]: (
                    <EmbeddedLink
                      to={t(props.contentEmbedLink.contentKey)}
                      linkName={props.contentEmbedLink.linkName}
                      screenName={getScreenName()}
                    />
                  ),
                }
              }
            >
              {replaceBrand(paragraph.trim())}
            </Trans>
          </p>
        ))}
      </>
    )
  }

  return (
    <Dialog
      className="turn-on-off-dialog"
      open={props.open}
      title={determineTurnOnOrTurnOffText()}
      subtitle={props.subtitle}
      primaryButtonLabel={determineTurnOnOrTurnOffText()}
      onPrimaryButtonCliked={props.onPrimaryButtonCliked}
      secondaryButtonLabel={t('lbl_cancel')}
      onSecondaryButtonCliked={props.onSecondaryButtonCliked}
      onCloseButtonCliked={props.onCloseButtonCliked}
    >
      {renderVehicleInfo()}
      {renderContent()}
    </Dialog>
  )
}
