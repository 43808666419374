import React from 'react'
import { useTranslation } from 'react-i18next'
import { analytics } from '../helperComponents/Analytics/Analytics'
import {
  AMPLITUDE_LINK_NAME,
  AMPLITUDE_SCREEN_NAME,
} from '../helperComponents/Analytics/AnalyticsConstants'
import FeatureTile, {FEATURE_TILE_TYPE} from './FeatureTile'
import HyperLink from '../components/HyperLink'
import {FEATURE_UI_NAME} from '../helperComponents/Constants'
import {replaceBrand, useBrandedTranslation} from '../helperComponents/contentUtils'

export default function PersonalizationTile({
  vinData,
  vehicleName
}) {
  const {t} = useTranslation()

  function connectedVehiclePrivacyNoticeClicked() {
    analytics.fireLinkTappedEvent(
      AMPLITUDE_SCREEN_NAME.MANAGE_DATA_WITH_FORD,
      AMPLITUDE_LINK_NAME.CONNECTED_VEHICLE_PRIVACY_NOTICE,
    )
  }

  return (
    <div className="feature-tile">
      <div className="feature-tile__header">
        <div className="feature-tile__header__title">{t('lbl_Personalization_and_Marketing')}</div>
      </div>
      <div className={'paragraph-truncated'}>{replaceBrand(t('lbl_Personalization_and_Marketing_desc_text'))}</div>
      {vinData?.map((feature) => {
        if (feature.uiName === FEATURE_UI_NAME.personalizationVehicleData) {
          return (
            <FeatureTile
              key={feature.uiName}
              feature={feature}
              vehicleName={vehicleName}
              type={
                feature.type.find((type) => type === 'EV') ? FEATURE_TILE_TYPE.LINK : FEATURE_TILE_TYPE.TOGGLE
              }
              learnMoreCategories={feature.learnMoreCategories}
            />
          )
        }
      })}

      {vinData?.map((feature) => {
        if(feature.uiName === FEATURE_UI_NAME.personalizationDrivingData) {
          return (
            <FeatureTile
              key={feature.uiName}
              feature={feature}
              vehicleName={vehicleName}
              type={
                feature.type.find((type) => type === 'EV') ? FEATURE_TILE_TYPE.LINK : FEATURE_TILE_TYPE.TOGGLE
              }
              learnMoreCategories={feature.learnMoreCategories}
            />
          )
        }
      })}

      <div className="mac-page__footer">
        {t('lbl_personalization_and_marketing_link_text')}
        <HyperLink
          className="mac-page__footer-link"
          text={t('lbl_connected_vehicle_privacy_notice')}
          onClick={connectedVehiclePrivacyNoticeClicked}
          redirectUrl={useBrandedTranslation('lbl_connected_vehicle_privacy_notice_url')}
        />
      </div>

    </div>
  )

}
