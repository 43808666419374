import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FeatureToggle from './FeatureToggle'
import ParagraphTruncated from '../components/ParagraphTruncated'
import LearnMoreDialog, { LEARN_MORE_DIAGLOG_TYPE } from '../dialog/LearnMoreDialog'
import { utils } from '../helperComponents/Utils'
import TurnOnOffDialog from '../dialog/TurnOnOffDialog'
import { analytics } from '../helperComponents/Analytics/Analytics'
import {
  AMPLITUDE_LINK_NAME,
  AMPLITUDE_RUNNING_BLOCK_NAME,
  AMPLITUDE_SCREEN_NAME,
  AMPLITUDE_SCREENNAME_MAP,
  AMPLITUDE_TOGGLE_STATUS,
} from '../helperComponents/Analytics/AnalyticsConstants'
import { APP_CONSTANT, FEATURE_UI_NAME } from '../helperComponents/Constants'
import { useDispatch, useSelector } from 'react-redux'
import { startApiRefreshing } from '../../redux/actions/LoadingActions'
import { getBrandedKey, getCountryKeyForGoogleConsent } from '../helperComponents/contentUtils'
import { saveConsent as saveConsentAction } from '../../redux/actions/ConsentActions'
import {replaceBrand} from '../helperComponents/contentUtils'

const noLearnMoreButtonList = [FEATURE_UI_NAME.vehicleLocationData, FEATURE_UI_NAME.vehicleData]
const brandSpecificTextList = [FEATURE_UI_NAME.californiaAirResourcesBoard, FEATURE_UI_NAME.vehicleHealthToDealers, FEATURE_UI_NAME.txu, FEATURE_UI_NAME.preferredDealer]

const TURN_OFF_CONTENT_KEY_MAP = new Map([
  [FEATURE_UI_NAME.californiaAirResourcesBoard, 'lbl_turn_off_California Air Resources Board_popup'],
  [FEATURE_UI_NAME.vehicleData, 'lbl_turn_off_Vehicle And Driving Data_popup'],
  [FEATURE_UI_NAME.vehicleLocationData, 'lbl_turn_off_Vehicle Location Data_popup'],
  [FEATURE_UI_NAME.preferredDealer, 'lbl_turn_off_Preferred Dealer'],
  [FEATURE_UI_NAME.personalization, ['lbl_turn_off_Personalization_popup_text1', 'lbl_turn_off_Personalization_popup_text2', 'lbl_turn_off_Personalization_popup_text3']],
  [FEATURE_UI_NAME.personalizationVehicleData, ['lbl_turn_off_PersonalizationVehicleData_popup_text1', 'lbl_turn_off_PersonalizationVehicleData_popup_text2',
    'lbl_turn_off_PersonalizationVehicleData_popup_text3', 'lbl_turn_off_PersonalizationVehicleData_popup_text4'
  ]],
  [FEATURE_UI_NAME.personalizationDrivingData, ['lbl_turn_off_PersonalizationDrivingData_popup_text1', 'lbl_turn_off_PersonalizationVehicleData_popup_text2',
    'lbl_turn_off_PersonalizationVehicleData_popup_text3', 'lbl_turn_off_PersonalizationVehicleData_popup_text4'
  ]],
  [FEATURE_UI_NAME.googleMapsInFordPass, 'lbl_turn_off_Google Maps in FordPass'],
  [FEATURE_UI_NAME.googleMapsInLincolnWay, 'lbl_turn_off_Google Maps in Lincoln Way'],
  [FEATURE_UI_NAME.txu, ['lbl_turn_off_TXU_para1', 'lbl_turn_off_TXU_para2', 'lbl_turn_off_TXU_para3']],
])

const TURN_ON_CONTENT_KEY_MAP = new Map([
  [
    FEATURE_UI_NAME.californiaAirResourcesBoard,
    [
      'lbl_turn_on_California Air Resources Board_popup',
      'lbl_turn_on_California Air Resources Board_popup_pt2',
      'lbl_turn_on_California Air Resources Board_popup_pt3',
      'lbl_turn_on_California Air Resources Board_popup_pt4',
    ],
  ],
  [FEATURE_UI_NAME.vehicleData, 'lbl_turn_on_Vehicle And Driving Data_popup'],
  [FEATURE_UI_NAME.vehicleLocationData, 'lbl_turn_on_Vehicle Location Data_popup'],
  [FEATURE_UI_NAME.preferredDealer, 'lbl_turn_on_Preferred Dealer_para1'],
  [FEATURE_UI_NAME.personalization, 'lbl_turn_on_text'],
  [FEATURE_UI_NAME.personalizationVehicleData, 'lbl_turn_on_PersonalizationVehicleData_popup'],
  [FEATURE_UI_NAME.personalizationDrivingData, 'lbl_turn_on_PersonalizationDrivingData_popup'],
  [
    FEATURE_UI_NAME.googleMapsInFordPass,
    [
      'lbl_turn_on_Google Maps in FordPass_para1',
      getCountryKeyForGoogleConsent('lbl_turn_on_Google Maps in FordPass_para2'),
    ],
  ],
  [
    FEATURE_UI_NAME.googleMapsInLincolnWay,
    [
      'lbl_turn_on_Google Maps in Lincoln Way_para1',
      getCountryKeyForGoogleConsent('lbl_turn_on_Google Maps in Lincoln Way_para2'),
    ],
  ],
  [
    FEATURE_UI_NAME.txu,
    [
      'lbl_turn_on_TXU_para1',
      'lbl_turn_on_TXU_para2',
      'lbl_turn_on_TXU_para3',
      'lbl_turn_on_TXU_para4',
      'lbl_turn_on_TXU_para5',
      'lbl_turn_on_TXU_para6',
    ],
  ],
])

const TURN_ON_CONTENT_KEYMAP_BY_BRAND = new Map([
  [
    FEATURE_UI_NAME.vehicleHealthToDealers,
    [
      'lbl_turn_on_Vehicle Health To Dealers_popup_pt1',
      'lbl_turn_on_Vehicle Health To Dealers_popup_pt2',
    ],
  ],
  [FEATURE_UI_NAME.preferredDealer, 'lbl_turn_on_Preferred Dealer_para1']
])

const CONTENT_EMBEDDED_LINK_MAP = new Map([
  [
    FEATURE_UI_NAME.googleMapsInFordPass,
    {
      varKey: 'privacyLink',
      contentKey: 'lbl_google_privacy_policy_link',
      linkName: AMPLITUDE_LINK_NAME.GOOGLE_POLICY_LINK,
    },
  ],
  [
    FEATURE_UI_NAME.googleMapsInLincolnWay,
    {
      varKey: 'privacyLink',
      contentKey: 'lbl_google_privacy_policy_link',
      linkName: AMPLITUDE_LINK_NAME.GOOGLE_POLICY_LINK,
    },
  ],
])

export const FEATURE_TILE_TYPE = Object.freeze({
  TOGGLE: 'toggle',
  LINK: 'link',
})

export default function FeatureTile({
  feature,
  vehicleName,
  type = FEATURE_TILE_TYPE.TOGGLE,
  learnMoreCategories,
}) {
  const { t } = useTranslation()
  const [showLearnMoreDialog, setShowLearnMoreDialog] = useState(false)
  const [showTurnOnOffDialog, setShowTurnOnOffDialog] = useState(false)
  const { apiLoadingInProgress } = useSelector((state) => state.loading)
  const activePage = useSelector((state) => state.activePage)
  const authorizedUsers = getAuthorizedUsers()
  function getAuthorizedUsers() {
    let vinUserDetails
    useSelector((state) => state.authorizedUsersForVin).forEach((vinuser) => {
      if (vinuser.vin === feature.vin) {
        vinUserDetails = vinuser.users
        return vinUserDetails
      }
    })
    return vinUserDetails
  }
  const [isToggleOn, setIsToggleOn] = useState(determineToggleStatus())
  const dispatch = useDispatch()
  const [buttonClicked, setButtonClicked] = useState(false)
  useEffect(() => {
    setIsToggleOn(determineToggleStatus())
  }, [feature.statusCode])

  useEffect(() => {
    if (!apiLoadingInProgress) {
      setIsToggleOn(determineToggleStatus())
      setShowTurnOnOffDialog(false)
      setButtonClicked(false)
    }
  }, [apiLoadingInProgress, feature])

  function determineToggleStatus() {
    if (feature.statusCode) {
      return feature.statusCode === 'Y'
    }
    if (feature.consentStatus) {
      return feature.consentStatus === '1'
    }
  }

  function displayToggle() {
    if (type === FEATURE_TILE_TYPE.TOGGLE) return <FeatureToggle value={isToggleOn} onToggle={onToggle} />
  }

  function displayLink() {
    if (type === FEATURE_TILE_TYPE.LINK)
      return (
        <div className="feature-tile__link-wrapper">
          <a className="feature-tile__link-wrapper__link" href={t('lbl_feature_tile_link_url')}>
            {t('lbl_feature_tile_link_label')}
          </a>
        </div>
      )
  }

  function displayLearnMoreButton() {
    if (!noLearnMoreButtonList.includes(feature.uiName))
      return (
        <button
          className="feature-tile__learn-more fmc-button fmc-button--outlined fmc-button-addon"
          onClick={onLearnMoreClicked}
        >
          {t('lbl_learn_more')}
        </button>
      )
  }

  function onLearnMoreClicked() {
    analytics.fireLearnMoreTappedEvent(AMPLITUDE_SCREENNAME_MAP.get(activePage), feature.uiName)
    setShowLearnMoreDialog(true)
  }

  function closeLearnMoreDialog() {
    analytics.fireGoBackTappedEvent(
      AMPLITUDE_SCREEN_NAME.LEARN_MORE,
      AMPLITUDE_SCREENNAME_MAP.get(activePage),
      AMPLITUDE_RUNNING_BLOCK_NAME.FOOTER,
      feature.uiName,
    )
    setShowLearnMoreDialog(false)
  }

  function exitLearnMoreDialog() {
    analytics.firePageExitTappedEvent(
      AMPLITUDE_SCREEN_NAME.LEARN_MORE,
      AMPLITUDE_SCREENNAME_MAP.get(activePage),
      feature.uiName,
    )
    setShowLearnMoreDialog(false)
  }

  function determineLearnMoreDialogType() {
    // return type === FEATURE_TILE_TYPE.LINK ? LEARN_MORE_DIAGLOG_TYPE.LINK : LEARN_MORE_DIAGLOG_TYPE.ACCORDION
    if (feature?.uiName === FEATURE_UI_NAME.preferredDealer) return LEARN_MORE_DIAGLOG_TYPE.NONE
    return LEARN_MORE_DIAGLOG_TYPE.ACCORDION
  }

  function displayLastUpdateInfo() {
    if (feature.timeStamp) {
      return (
        <div className="feature-tile__last-update-info">
          {utils.formatDate(feature.timeStamp, feature.triggerUserId, authorizedUsers)}
        </div>
      )
    }
  }

  function onToggle() {
    handleToggleAnalyticsEvents()
    setShowTurnOnOffDialog(true)
  }

  function handleToggleAnalyticsEvents() {
    analytics.fireToggleTappedEvent(
      AMPLITUDE_SCREENNAME_MAP.get(activePage),
      feature.uiName,
      isToggleOn ? AMPLITUDE_TOGGLE_STATUS.TURN_ON : AMPLITUDE_TOGGLE_STATUS.TURN_OFF,
    )
  }

  function getBrandedContentByMap(keyMap) {
    const CONTENT_KEYS = keyMap.get(feature.uiName);
    if (Array.isArray(CONTENT_KEYS)) {
      const CONTENT_ARRAY = CONTENT_KEYS.map((key) => t(getBrandedKey(key)))
      return CONTENT_ARRAY.join(' ')
    }
    return t(getBrandedKey(CONTENT_KEYS))
  }

  function getContentByMap(keyMap) {
    const CONTENT_KEYS = keyMap.get(feature.uiName);
    if (Array.isArray(CONTENT_KEYS)) {
      const CONTENT_ARRAY = CONTENT_KEYS.map((key) => t(key))
      return CONTENT_ARRAY.join(' ')
    }
    return t(CONTENT_KEYS)
  }

  function getTurnOffText() {
    let TURN_OFF_CONTENT_KEY;
    if(feature.uiName === FEATURE_UI_NAME.vehicleHealthToDealers){
      TURN_OFF_CONTENT_KEY = getBrandedKey('lbl_turn_off_Vehicle Health To Dealers_popup');
    } else {
      TURN_OFF_CONTENT_KEY= TURN_OFF_CONTENT_KEY_MAP.get(feature.uiName)
    }
    if (Array.isArray(TURN_OFF_CONTENT_KEY)) {
      const TURN_OFF_CONTENT_ARRAY = TURN_OFF_CONTENT_KEY.map((key) => t(key))
      return TURN_OFF_CONTENT_ARRAY.join(' ')
    }
    return t(TURN_OFF_CONTENT_KEY)
  }

  function getTurnOnText() {
    if(feature.uiName === FEATURE_UI_NAME.vehicleHealthToDealers || feature.uiName === FEATURE_UI_NAME.preferredDealer){
      return getBrandedContentByMap(TURN_ON_CONTENT_KEYMAP_BY_BRAND)
    } else {
      return getContentByMap(TURN_ON_CONTENT_KEY_MAP)
    }
  }

  function getEmbeddedContentLink() {
    return CONTENT_EMBEDDED_LINK_MAP.get(feature.uiName)
  }

  function getTurnOnOffContentKey() {
    return isToggleOn ? getTurnOffText() : getTurnOnText()
  }

  function getEmbeddedContentLink() {
    return CONTENT_EMBEDDED_LINK_MAP.get(feature.uiName)
  }

  function getTurnOnOffContentKey() {
    return isToggleOn ? getTurnOffText() : getTurnOnText()
  }

  function savePrivacy(status) {
    utils.dispatchSavePrivacy(
      dispatch,
      feature,
      feature.vin,
      status,
      feature.preferredDealerId ? feature.preferredDealerId : null,
      APP_CONSTANT.CONFIG.APPLICATION_ID_FOR_SAVE,
      null,
    )
  }

  function saveConsent(consentStatus) {
    const { applicationId, llId, consentContext, appCountryCode, vin } = feature
    dispatch(
      saveConsentAction({
        applicationId,
        vin,
        llId,
        consentContext,
        appCountryCode,
        consentStatus,
      }),
    )
  }

  function handleTurnOnOffDialogConfirmation() {
    if (!buttonClicked) {
      if (isToggleOn) {
        analytics.fireToggleConfirmationTappedEvent(AMPLITUDE_SCREEN_NAME.TURN_OFF, feature.uiName)
        feature.llId ? saveConsent('0') : savePrivacy('N')
      } else {
        analytics.fireToggleConfirmationTappedEvent(AMPLITUDE_SCREEN_NAME.TURN_ON, feature.uiName)
        feature.llId ? saveConsent('1') : savePrivacy('Y')
      }
      setButtonClicked(true)
    }
    dispatch(startApiRefreshing())
  }

  function closeTurnOnOffDialog() {
    analytics.fireCancelTappedEvent(
      isToggleOn ? AMPLITUDE_SCREEN_NAME.TURN_OFF : AMPLITUDE_SCREEN_NAME.TURN_ON,
      AMPLITUDE_SCREENNAME_MAP.get(activePage),
      feature.uiName,
    )
    setShowTurnOnOffDialog(false)
  }

  function exitTurnOnOffDialog() {
    analytics.firePageExitTappedEvent(
      isToggleOn ? AMPLITUDE_SCREEN_NAME.TURN_OFF : AMPLITUDE_SCREEN_NAME.TURN_ON,
      AMPLITUDE_SCREENNAME_MAP.get(activePage),
      feature.uiName,
    )
    setShowTurnOnOffDialog(false)
  }

  function getInfoText() {
    const textValue = t(feature.infoText)

      if (brandSpecificTextList.includes(feature.uiName) || feature.uiName === FEATURE_UI_NAME.txu) {
        if (APP_CONSTANT.CONFIG.BRAND_CODE === 'F') {
          return t(feature.infoTextF)
        }
      }

      if (brandSpecificTextList.includes(feature.uiName)) {
        if (APP_CONSTANT.CONFIG.BRAND_CODE === 'L') {
          return t(feature.infoTextL)
        }
      }

    return replaceBrand(textValue)
  }

  function getFeatureNameForPopup() {
    return t(`lbl_${feature.uiName}`)
  }

  return (
    <div className="feature-tile">
      <div className="feature-tile__header">
        <div className="feature-tile__header__title">{t(`lbl_${feature.uiName}`)}</div>
        {displayToggle()}
      </div>
      <ParagraphTruncated infoText={getInfoText()} featureName={feature.uiName} />
      {displayLearnMoreButton()}
      {displayLink()}
      {displayLastUpdateInfo()}
      <hr className="fmc-divider--horizontal" />

      {showLearnMoreDialog && (
        <LearnMoreDialog
          type={determineLearnMoreDialogType()}
          open={true}
          contentEmbedLink={getEmbeddedContentLink()}
          featureName={feature.uiName}
          learnMoreCategories={learnMoreCategories}
          onPrimaryButtonCliked={closeLearnMoreDialog}
          onCloseButtonCliked={exitLearnMoreDialog}
        />
      )}

      {showTurnOnOffDialog && (
        <TurnOnOffDialog
          open={true}
          isTurnOn={isToggleOn}
          subtitle={getFeatureNameForPopup()}
          vehicleName={vehicleName}
          content={getTurnOnOffContentKey()}
          contentEmbedLink={getEmbeddedContentLink()}
          onPrimaryButtonCliked={handleTurnOnOffDialogConfirmation}
          onSecondaryButtonCliked={closeTurnOnOffDialog}
          onCloseButtonCliked={exitTurnOnOffDialog}
        />
      )}
    </div>
  )
}
