import {useTranslation} from 'react-i18next'
import React, {useEffect, useState} from 'react'
import FeatureTile, {FEATURE_TILE_TYPE} from './FeatureTile'
import SelectVehicle from './SelectVehicle'
import {useNavigate} from 'react-router-dom'
import UnlinkThirdPartyAccounts from './UnlinkThirdPartyAccounts'
import {useDispatch, useSelector} from 'react-redux'
import {analytics} from '../helperComponents/Analytics/Analytics'
import {
  AMPLITUDE_RUNNING_BLOCK_NAME,
  AMPLITUDE_SCREEN_NAME,
} from '../helperComponents/Analytics/AnalyticsConstants'
import {setSelectedVehicleData, setSelectedVin} from '../../redux/actions/TabActions'
import {utils} from '../helperComponents/Utils'
import {useBrandedTranslation} from '../helperComponents/contentUtils'
import {addInfoTexts} from '../helperComponents/DataProcessor'
import {APP_CONSTANT, FEATURE_UI_NAME} from '../helperComponents/Constants'
import {concat} from 'lodash'
import PersonalizationTile from './PersonalizationTile'

export const MANAGE_DATA_LABEL = {
  THIRD_PARTIES: 'third_parties',
}

export default function ManageData(props) {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedVin = useSelector((state) => state.selectedVin)
  const vehicleData = useSelector((state) => state.selectedVehicleData)
  const selectedVehicleData = vehicleData ? vehicleData : props.vehicleData
  const [selectedVinData, setSelectedVinData] = selectedVin
    ? useState(props.featuresByVin.get(selectedVin))
    : useState(props.featuresByVin.get(props.vehicleData[0]?.vin))
  const isDialogOpen = useSelector((state) => state.isDialogOpen)
  const getVehicleName = (vin) => {
    return selectedVehicleData.find((obj) => {
      return obj.vin === vin
    })?.vehicleName
  }
  const [hasAccountSpecificConsent, setHasAccountSpecificConsent] = useState(
    props.manageUserDataWithThirdParties?.length > 0,
  )
  const [shouldFirePageViewEvent, setShouldFirePageViewEvent] = useState(true)

  const [selectedVehicleName, setSelectedVehicleName] = selectedVin
    ? useState(getVehicleName(selectedVin))
    : useState(getVehicleName(props.vehicleData[0]?.vin))

  const [selectedUnlinkData, setSelectedUnlinkData] =
    props.unlinkTPAccounts !== undefined && props.unlinkTPAccounts.length
      ? useState(props.unlinkTPAccounts.find((data) => data.key === selectedVin))
      : useState()

  const hasPersonalizationVDD = selectedVinData.some(item => item.uiName === FEATURE_UI_NAME.personalizationVehicleData) &&
    selectedVinData.some(item => item.uiName === FEATURE_UI_NAME.personalizationDrivingData)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (props.vehicleData.length > 0 && props.featuresByVin.size > 0) {
      setSelectedVinData(props.featuresByVin.get(selectedVin ? selectedVin : props.vehicleData[0]?.vin))
    }

    setHasAccountSpecificConsent(props.manageUserDataWithThirdParties?.length > 0)
  }, [props.vehicleData, selectedVin, props.featuresByVin])

  useEffect(() => {
    if (selectedVin && shouldFirePageViewEvent) {
      const features = props.manageUserDataWithThirdParties
        ? concat(selectedVinData, props.manageUserDataWithThirdParties)
        : selectedVinData
      analytics.firePageViewEvent(getScreenName(), features)
      setShouldFirePageViewEvent(false)
    }
  }, [selectedVin, selectedVinData, shouldFirePageViewEvent, props.manageUserDataWithThirdParties])

  function getScreenName() {
    return props.label === MANAGE_DATA_LABEL.THIRD_PARTIES
      ? AMPLITUDE_SCREEN_NAME.MANAGE_DATA_WITH_THIRD_PARTIES
      : AMPLITUDE_SCREEN_NAME.MANAGE_DATA_WITH_FORD
  }

  function fireVehicleSelectedEvent(vin) {
    const vehicleName = selectedVehicleData.find((vehicle) => vehicle.vin === vin).vehicleName
    analytics.fireVehicleSelectedEvent(getScreenName(), vehicleName)
  }

  function onInitVehicleSelectOption(vin) {
    handleVinChanged(vin)
  }

  function onVehicleSelected(vin) {
    fireVehicleSelectedEvent(vin)
    handleVinChanged(vin)
  }

  function handleVinChanged(vin) {
    dispatch(setSelectedVin(vin))
    dispatch(setSelectedVehicleData(props.vehicleData))
    setSelectedVinData(props.featuresByVin.get(vin))
    setShouldFirePageViewEvent(true)
    if (props.unlinkTPAccounts !== undefined && props.unlinkTPAccounts.length) {
      setSelectedUnlinkData(props.unlinkTPAccounts.find((data) => data.key === vin))
    }
    setSelectedVehicleName(getVehicleName(vin))
  }

  function renderSelectVehicle() {
    if (!props.vehicleData.length > 0) return

    let className = 'manage-data__select-vehicle'
    if (shouldDisplayAccountSpecificConsent())
      className += ' manage-data__select-vehicle--extra-margin-bottom'

    return (
      <div className={className}>
        <SelectVehicle
          vehicleData={selectedVehicleData}
          onInit={onInitVehicleSelectOption}
          onSelect={onVehicleSelected}
          defaultVehicle={selectedVin ? selectedVin : props.vehicleData[0]?.vin}
        />
      </div>
    )
  }

  function renderAccountSpecificTiles() {
    return addInfoTexts(props.manageUserDataWithThirdParties).map((feature) => {
      return (
        <FeatureTile
          key={feature.uiName}
          feature={feature}
          type={FEATURE_TILE_TYPE.TOGGLE}
          learnMoreCategories={feature.learnMoreCategories}
        />
      )
    })
  }

  function renderAccountSpecificContents() {
    return (
      <>
        {renderAccountSpecificTiles()}
        {renderSelectVehicle()}
      </>
    )
  }

  function shouldDisplayAccountSpecificConsent() {
    return !APP_CONSTANT.CONFIG.IS_OWNER && hasAccountSpecificConsent
  }

  function handleGoBackClicked() {
    analytics.fireGoBackTappedEvent(
      getScreenName(),
      AMPLITUDE_SCREEN_NAME.PRIVACY_LANDING_PAGE,
      AMPLITUDE_RUNNING_BLOCK_NAME.HEADER,
    )
    navigate(-1)
  }

  return (
    <div>
      <div
        className={`manage-data__header-container ${
          shouldDisplayAccountSpecificConsent() && 'manage-data__header-container--no-select-option'
        }`}
      >
        <div>
          <button
            aria-hidden={isDialogOpen}
            tabIndex={utils.getTabIndex(isDialogOpen)}
            className="manage-data__go-back-cta"
            onClick={handleGoBackClicked}
          >
            <span className="fds-icon fds-icon fds-icon--offset-left fds-font--ford-icons__chevron-left" />
            {t('lbl_go_back')}
          </button>
          <div className={'manage-data__header'}>
            <div className={'manage-data__header__title'}>
              {props.label ? t(`lbl_manage_data_${props.label}`) : useBrandedTranslation('lbl_manage_data')}
            </div>
          </div>
        </div>

        {!shouldDisplayAccountSpecificConsent() && renderSelectVehicle()}
      </div>
      <div className="manage-data__body">
        {shouldDisplayAccountSpecificConsent() && renderAccountSpecificContents()}

        {selectedVinData?.map((feature) => {
          if (feature.uiName !== 'PersonalizationVehicleData' && feature.uiName !== 'PersonalizationDrivingData') {
            return (
              <FeatureTile
                key={feature.uiName}
                feature={feature}
                vehicleName={selectedVehicleName}
                type={
                  feature.type.find((type) => type === 'EV') ? FEATURE_TILE_TYPE.LINK : FEATURE_TILE_TYPE.TOGGLE
                }
                learnMoreCategories={feature.learnMoreCategories}
              />
            )
          }
        })}

        {hasPersonalizationVDD &&
          (
            <PersonalizationTile
              vinData={selectedVinData}
              vehicleName={selectedVehicleName}
            />
          )
        }

        {selectedUnlinkData &&
        selectedUnlinkData.applications &&
        selectedUnlinkData.applications.length > 0 ? (
          <UnlinkThirdPartyAccounts unlinkApplications={selectedUnlinkData.applications} />
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
